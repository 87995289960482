import { Component, ElementRef, Input } from '@angular/core'
import { IGalleryEntry } from 'src/app/data/models/IGalleryEntry'

@Component({
  selector: 'app-gallery-entry',
  templateUrl: './gallery-entry.component.html',
  styleUrls: ['./gallery-entry.component.sass']
})
export class GalleryEntryComponent {
  @Input('entry') entry?: IGalleryEntry

  constructor(public hostElement: ElementRef) {}
}
