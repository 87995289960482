import { Component, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core'

import { GalleryService } from 'src/app/networking/gallery.service'
import { SharedDataService } from 'src/app/helper/services/shared-data.service'

import { IGalleryEntry } from 'src/app/data/models/IGalleryEntry'
import { IMilestone } from 'src/app/data/models/IMilestone'
import { GalleryEntryComponent } from '../components/gallery-entry/gallery-entry.component'
import { MilestoneComponent } from '../components/milestone/milestone.component'
import { StorageService } from 'src/app/helper/services/storage.service'
import { CONSTANTS } from 'src/app/helper/constants'
import { HelperService } from 'src/app/helper/services/helper.service'

export type State = 'milestones' | 'gallery'

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.sass']
})
export class GalleryComponent implements OnInit {

  gallery: Array<IGalleryEntry> = []
  milestones: Array<IMilestone> = []
  state: State = 'gallery'

  isHandset: boolean = false

  @ViewChild('galleryContainer') galleryContainer?: any
  @ViewChildren('milestone') milestoneList?: QueryList<MilestoneComponent>
  @ViewChildren('galleryEntry') galleryEntryList?: QueryList<GalleryEntryComponent>

  constructor(
    private galleryService: GalleryService,
    public sharedDataService: SharedDataService,
    private storageService: StorageService,
    private helperService: HelperService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getGallery()
    this.sharedDataService.isHandset$.subscribe(isHandset => this.isHandset = isHandset)
  }

  checkScroll() {
    if(this.isHandset)
      return

    const milestonesArray = this.milestoneList?.toArray() ?? []
    const entriesArray = this.galleryEntryList?.toArray()
    let startIndex = 0

    entriesArray?.forEach(entry => {
      if(entry.entry?.milestones == null)
        return

      let milestones = milestonesArray.filter(ms => ms.milestone?.id === entry.entry?.id)
      let position: string

      if(entry.hostElement.nativeElement.getBoundingClientRect().bottom <= 0) {
        position = 'relative'
        startIndex += entry.entry.milestones.length
      } else
        position = 'sticky'

      milestones.forEach(milestone => {
        this.renderer.setStyle(milestone.hostElement.nativeElement, 'position', position)
      })      

      this.setMilestonesPosition(startIndex)
    })
  }

  async getGallery() {
    if(!this.storageService.loadData(CONSTANTS.storageKeyData)){
      this.helperService.navigateToLogin()
    }

    [this.gallery, this.milestones] = await Promise.all([this.galleryService.getGallery(), this.galleryService.getMilestones()])
    this.milestoneList?.changes.subscribe(() => this.setMilestonesPosition(0))
  }

  private setMilestonesPosition(startIndex: number) {
    this.milestoneList?.toArray().forEach((milestone: any, index: number) => {
      let milestoneElement = milestone.hostElement.nativeElement
      let top = milestoneElement.offsetHeight * (index + 1 - startIndex)
      this.renderer.setStyle(milestoneElement, 'top', `${top}px`)
    })
  }

  async logout() {
    this.galleryService.logout()
    this.helperService.navigateToLogin()
  }
}
