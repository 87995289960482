import { Component } from '@angular/core'
import { SharedDataService } from './helper/services/shared-data.service'
import { environment } from '../environments/environment'
import { CONSTANTS } from './helper/constants'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {

  title = 'NeoGallery'
  constants = CONSTANTS
  version: string = environment.version

  constructor(
    public sharedDataService: SharedDataService
  ) { }
}
